/* eslint-disable react/no-unescaped-entities, max-len */

import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import styled from 'styled-components';
import DefaultLayout from '../containers/default';
import MainWrap from '../components/layout/wrap';
import {
  DocumentTitle,
  Paragraph,
  ParagraphLi,
  SubTitle,
} from '../components/legal-formatting';
import { styles } from '../styles/globals';

const linkStyles = `
  color: ${styles.baseFontColor};
  font-weight: bold;
`;

const PageLink = styled(Link)`${linkStyles}`;
const AnchorLink = styled.a`${linkStyles}`;

export default () => (
  <DefaultLayout>
    <Helmet>
      <title>all.health FAQ</title>
      <meta name="description" content="all.health FAQ" />
    </Helmet>
    <MainWrap>
      <DocumentTitle>all.health FAQ</DocumentTitle>
      <Paragraph as="div">
        <ul style={{ listStyleType: 'none' }}>
          <ParagraphLi>
            <AnchorLink href="#why-all-health">Why all.health?</AnchorLink>
          </ParagraphLi>
          <ParagraphLi>
            <AnchorLink href="#access">How can I get access to the all.health system?</AnchorLink>
          </ParagraphLi>
          <ParagraphLi>
            <AnchorLink href="#cost">How much does it cost?</AnchorLink>
          </ParagraphLi>
          <ParagraphLi>
            <AnchorLink href="#compare">How does all.health compare with popular health, fitness and wellness wearables?</AnchorLink>
          </ParagraphLi>
          <ParagraphLi>
            <AnchorLink href="#privacy">How do you ensure privacy of patient data?</AnchorLink>
          </ParagraphLi>
          <ParagraphLi>
            <AnchorLink href="#app">How do I get the app and sign up?</AnchorLink>
          </ParagraphLi>
          <ParagraphLi>
            <AnchorLink href="#pairing">Questions about pairing your band or our smartphone application?</AnchorLink>
          </ParagraphLi>
          <ParagraphLi>
            <AnchorLink href="#deletion">How do I delete my data?</AnchorLink>
          </ParagraphLi>
        </ul>
      </Paragraph>
      <SubTitle id="why-all-health">Why all.health?</SubTitle>
      <Paragraph>
        Our mission is to help maximize the healthspan of members who use our
        system enabling them to live their best, most productive and healthiest
        lives for the longest period of time.
      </Paragraph>
      <Paragraph>
        Our platform is an informed link to your care team, whenever and wherever
        you need it. It walks with you every step of the way to help you explore
        the impact of your everyday lifestyle choices and live better.
      </Paragraph>
      <Paragraph>
        all.health's Integrated Continuous Management (ICM™) captures and correlates
        continuous vital sign information and scans for elevated risk of over 50
        health conditions — including respiratory infections, diabetes, hypertension,
        arrhythmias, sleep apnea and behavioral health factors. It helps coach the individual
        based on their evolving health profile and suggests possible next best steps to care teams.
      </Paragraph>
      <Paragraph>
        We are deeply rooted in science, with a world-class team of physicians, a rigorous scientific
        and clinical process, peer-reviewed publications, and over 1000 IP assets.
      </Paragraph>
      <Paragraph>
        The all.health system includes a lightweight wristband, and a mobile app linked to a
        healthcare provider portal. It's the first FDA-listed device for monitoring wearable
        vital signs like heart rate, breathing rate, and blood oxygenation and an easy-to-integrate
        platform, forging a continuous, intelligent link between providers and patients that
        offers comprehensive insight into health over time.
      </Paragraph>
      <Paragraph>
        For more information, check out our
        {' '}
        <PageLink to="/technology">technology</PageLink>
        {' '}
        page.
        You can also click
        {' '}
        <PageLink to="/what-it-means-for-you">here</PageLink>
        {' '}
        to understand
        what ICM means for you.
      </Paragraph>
      <SubTitle id="access">How can I get access to the all.health system?</SubTitle>
      <Paragraph>
        all.health is designed as a great consumer experience offering insights and
        coaching, but also as an integrated part of a trusted health provider service.
        The service will be available initially through providers and employers, including:
      </Paragraph>
      <Paragraph isList as="div">
        <ul>
          <ParagraphLi>Froedtert Health in Wisconsin</ParagraphLi>
          <ParagraphLi>Bassett Health in New York</ParagraphLi>
        </ul>
      </Paragraph>
      <Paragraph>
        If you are a health system or employer interested in our system, please reach out
        to us on
        {' '}
        <AnchorLink href="mailto:sales&#064;all.health">sales&#064;all.health</AnchorLink>
        .
      </Paragraph>
      <SubTitle id="cost">How much does it cost?</SubTitle>
      <Paragraph>
        We are a SaaS (subscription as a service) platform delivered through your provider or employer.
        Our service is designed for low cost, high accuracy and continuous use. If you are a health
        system or employer interested in our system, please reach out to us on
        {' '}
        {' '}
        <AnchorLink href="mailto:sales&#064;all.health">sales&#064;all.health</AnchorLink>
        .
      </Paragraph>
      <SubTitle id="compare">How does all.health compare with popular health, fitness and wellness wearables?</SubTitle>
      <Paragraph>
        Our ICM system includes a state of the art wearable that connects to your trusted
        care team, combining world class consumer health and wellness
        experience with an informed connection to your healthcare provider.
      </Paragraph>
      <Paragraph>
        This wristband is designed to blend in with users' lifestyles, built lightweight,
        and equipped with a battery that lasts several days. Most wearable devices today
        use green light sensors to record wearers' heart rate, among other health indicators.
        all.health uses a broad light spectrum, including the first blue and purple light
        sensor that not only makes our health measurements more comprehensive and accurate,
        but it enables new types of measurements to scan for a broader array of disease risks.
      </Paragraph>
      <Paragraph>
        We are, however, much more than a wearable. For the first time, all.health unites
        clinical grade medical technology with the expertise of your local care team to enable
        the best consumer healthcare experience. This continuous link between patients and
        their care teams provides a longitudinal view of comprehensive health data and enables
        providers to take action based on a medical continuum rather than a single snapshot.
        Our ICM system collects 24/7 data on vital signs and other health metrics while screening people
        for their risk of over 50 health conditions, and our AI models feed insights at the right
        moment to enable personalized, proactive, and predictive healthcare.
      </Paragraph>
      <Paragraph>
        The all.health ICM system incorporates a device that is US FDA-listed to produce
        clinical-grade pulse rate, breathing rate, and pulse oximetry.
        Note: our ICM system is not intended to diagnose, cure, treat nor prevent disease.
      </Paragraph>
      <SubTitle id="privacy">How do you ensure privacy of patient data?</SubTitle>
      <Paragraph>
        We abide by the highest data privacy standards, such as HIPAA. Data is shared between
        the user and their trusted Care Team, or as required by law, or when a user gives us
        permission to share their data.
      </Paragraph>
      <SubTitle id="app">How do I get the app and sign up?</SubTitle>
      <Paragraph>
        Our app and service are currently available by invite only through a select number of
        providers and employers. If you have already received an all.health invite code by email,
        you can continue by following the details in the email to download the app and sign up.
      </Paragraph>
      <SubTitle id="pairing">Questions about pairing your band or our smartphone application?</SubTitle>
      <Paragraph>
        Please visit the customer service section in your app.
      </Paragraph>
      <SubTitle id="deletion">How do I delete my data?</SubTitle>
      <Paragraph>
        You can request that we delete your data by emailing us at
        {' '}
        {' '}
        <AnchorLink href="mailto:support&#064;all.health">support&#064;all.health</AnchorLink>
        .
        Use “delete data” in the subject line, and include your user id in the body of the email. We will then get back to you.
      </Paragraph>
    </MainWrap>
  </DefaultLayout>
);
